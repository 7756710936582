import React from 'react'
import Layout from '../components/layout/Layout';
import { SocialIcons } from '../components/elements/SocialIcons';
import { social_links } from '../data/Navigation';
import FancyLink from '../components/elements/FancyLink';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/SEO';

const About = ({ data }) => {


    const images = data?.allFile.nodes

    const Sam = images.filter(image => image.name === "sam-about")[0]
    const Antler = images.filter(image => image.name === "antler-about")[0]
    const Book = images.filter(image => image.name === "ueg-about")[0]
    const Currikula = images.filter(image => image.name === "currikula-logo-about")[0]
    const SMIH = images.filter(image => image.name === "smih-logo")[0]
    const KATCH = images.filter(image => image.name === "katchlogo-rotate")[0]


    const aboutLinks = social_links.filter(link => !link.linkTo.includes("mailto") && !link.linkTo.includes("linkedin"))

    return (
            <Layout>
                 <SEO
                title="About Me"
                description="Find out more about Sam, what he has been up to, what he has done and what he might be thinking about doing."
            />
                <section className="max-w-screen-lg mx-auto grid md:grid-cols-2 my-12 px-4 md:px-0">

                    <div className="px-12">
                        <Img fluid={Sam.childImageSharp.fluid}></Img>
                    </div>
                    <div className="flex flex-col justify-center">
                        <h1 className="leading-normal font-heading text-4xl">It always feels weird writing “hi” in a bio.</h1>
                        <p className="">
                            I'm a developer, designer and hobbyist photographer. I've taken a startup through an accelerator, raised seed funding and failed to raise a Series A. I love making things. I'm fascinated by Natural Language Programming, and I'm determined to continually be learning. In my spare time, I write  <FancyLink linkTo="https://blog.samloyd.io">here</FancyLink> about things I’ve learned reading and living. </p>
                        <SocialIcons className="flex space-x-4 mt-4 text-gray-400 justify-center md:justify-start" itemClassName="hover:text-gray-800" icons={aboutLinks} />
                    </div>
                </section>
                <h2 className="text-center mb-24 md:mb-12 mt-12 font-heading px-4 md:px-0 text-3xl">Some other things about me</h2>

                <section
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="max-w-screen-md mx-auto my-32"
                >
                    <Card
                        fluid={KATCH.childImageSharp.fluid}
                        left={true}
                    >
                        <Text>
                        I have partnered with Katch, a marketing, PR and branding agency based in Dubai and London as their Director of Disruption. I provide the web, app and development skills to offer their clients a more comprehensive range of products and services. Find out more about Katch <FancyLink linkTo="https://katchinternational.com/">here</FancyLink>.
                        </Text>
                    </Card>


                </section>
                <section
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="max-w-screen-md mx-auto my-32"
                >
                    <Card
                        fluid={SMIH.childImageSharp.fluid}
                        left={false}
                    >
                        <Text>
                            I recently created a small SAAS business to help venues keep customer trust and implement the NHS Test and Trace program. It is called Sign Me In Here and you can find it <FancyLink linkTo="https://signmeinhere.com">here</FancyLink>.
                        </Text>
                    </Card>


                </section>
                <section
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="max-w-screen-md mx-auto my-32"
                >
                    <Card
                        fluid={Antler.childImageSharp.fluid}
                    >
                        <Text>
                            I currently run a digital presence consultancy called <FancyLink linkTo="https://antler.digital"> Antler Digital</FancyLink>, helping companies put their best foot forward on the web. We work as a freelance collective and help our clients with branding, design, development and content creation. We can help companies at any stage of their life. 
                        </Text>
                    </Card>


                </section>
                <section
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="max-w-screen-md mx-auto my-32"
                >
                    <Card
                        fluid={Currikula.childImageSharp.fluid}
                        left={false}

                    >
                        <Text>
                            A few years ago, I co-founded an EdTech startup called Currikula. We raised money, joined Ignite Accelerator, had over 10,000 users, and our customer base was growing. But it wasn’t enough, and we ceased operations in early 2019.
                        </Text>
                    </Card>


                </section>
                <section
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="max-w-screen-md mx-auto my-32"
                >
                    <Card
                        fluid={Book.childImageSharp.fluid}
                    >
                        <Text>
                            During my tenure at Currikula I wrote a book on best essay writing practises with my co-founder, Justin. It is called <FancyLink linkTo="https://theultimateessayguide.com"> The Ultimate Essay Guide</FancyLink> and gives students actionable tips on how to improve their essays quickly. You can find an ebook and paperback copy on <FancyLink linkTo="https://www.amazon.co.uk/dp/B07FKR1S2F/">Amazon</FancyLink>.
                    </Text>
                    </Card>


                </section>
            </Layout>
    )
}


export const AboutPageQuery = graphql`
    query AboutPageQuery {
        allFile(filter: {relativeDirectory: {eq: "pages/about"}}) {
        nodes {
            name
            childImageSharp {
            fluid(maxWidth:600) {
                ...GatsbyImageSharpFluid
                }
            }
        }
        }
    }
`


const Text = ({ className, children, ...restProps }) => <p className={`shadow p-8 mx-4 md:mx-0 relative z-10 rounded bg-white  font-light  leading-loose ${className}`} {...restProps}>{children}</p>

const Card = ({ className, children, fluid, left = true, ...restProps }) => <div className={`relative ${className}`} {...restProps}>
    {fluid && left && <Img className="w-24 lg:w-32 absolute left-0 lg:-ml-24 ml-8 -mt-24 transform rotate-45 lg:rotate-0" style={{ position: 'absolute' }} fluid={fluid}></Img>}
    {children}
    {fluid && !left && <Img className="w-24 lg:w-32 absolute right-0 top-0 lg:-mr-16 mr-8 lg:-mt-24 -mt-20 transform -rotate-45 lg:rotate-0" style={{ position: 'absolute' }} fluid={fluid}></Img>}

</div>
export default About
